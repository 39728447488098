@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Titillium+Web:wght@200;300;400;600;700;900&display=swap");
@import url(./styles/vars.css);

@font-face {
  font-family: "Nine Tsuki";
  font-weight: 400;
  font-style: normal;
  src: url("./assets//fonts//NineTsukiRegular.ttf") format("truetype");
}
.font-roboto {
  font-family: "Inter", Arial, sans-serif;
}

.font-mulish {
  font-family: "Inter", Arial, sans-serif;
}

.civic-pass--hidden {
  margin-top: -73px;
  min-height: 100px;
}

body {
  margin: 0;
  font-family: "Titillium Web", sans-serif;
  background-color: #17161b;
}
