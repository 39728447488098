@import "../../styles/helpers";

.drip {
    position: absolute;
    bottom: -1.05vw;
    z-index: 10;
    width: 100vw;
    padding-bottom: 10.5vw;
    margin-bottom: 1vw;
    background: url('./../../assets/images/drip-reverse-black.png'); }
