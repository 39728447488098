@import "../../styles/helpers";

.footer {
    color: $white;
    background-color: #aa4036;
    margin: 0 auto;
    padding: 5rem; }

.picContainer {
    width: 100%;
    display: flex;
    justify-content: center; }

.picContainer img {
    margin: 0 auto;
    min-width: 150px;
    width: 18%; }

.logo {
    font-family: Nine Tsuki;
    font-size: 6vw;
    @include a {
        font-size: 14vw; } }
